import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title } from "./config";
import { createItem, updateItem } from "utils/services";

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = {...form};

		if (this.props._id !== null) {
			setForm = {
				conditions : this.props.form.conditions,
				type_blocked : this.props.form.type_blocked,
				code_blocked: this.props.form.code_blocked,
				type_logic :this.props.form.type_logic,
                description:this.props.form.description
			};
		}

		this.state = {
			form: setForm,
			elements: [],
			conditions:[],
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
	}

	formChange(event) {
		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: event.target.value
			}
		});
	}

	sendData(form, _id) {
		if (_id === null) {
			createItem(entity, form, this.props.setData);
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		}

		this.props.changeView(false);
	}

	componentWillMount(){
		const {form} = this.state;
		this.setState({
			conditions: form.conditions
		})
	}

	render() {
		const { _id, form,conditions } = this.state;

		const addCondition = () => {
			conditions.push(form.conditions);
			this.setState({
				form : {
					conditions: conditions,
					type_blocked: form.type_blocked,
					code_blocked: form.code_blocked,
					type_logic: form.type_logic,
					description: form.description
				}
			});
		}

		const deleteCondition = (index) => {
			conditions.splice(index,1);
			this.setState({
				form : {
					conditions: conditions,
					type_blocked: form.type_blocked,
					code_blocked: form.code_blocked,
					type_logic: form.type_logic,
					description: form.description
				}
			});
		}

		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
											  <Col lg="6">
											  	<Row>
												  <Col lg="8">
													<FormGroup>
														<label className="form-control-label">Conditions</label>
														<Input
															className="form-control-alternative"
															name="conditions"
															placeholder="Conditions"
															type="text"
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
															<FormGroup>
																<label className="form-control-label" htmlFor="source">-</label>
																<Button className="btn-block" color="" onClick={() => addCondition()}>Add</Button>
															</FormGroup>
														</Col>
												  </Row>
											  </Col>
												<Col lg="3">
													<FormGroup>
														<label className="form-control-label">Type Bloqued</label>
														<Input
															className="form-control-alternative"
															name="type_blocked"
															placeholder="Type Blocked"
															type="select"
															value={form.type_blocked}
															onChange={this.formChange}
														>
														<option value="element">Element</option>
														<option value="option">Option</option>
														</Input>
													</FormGroup>
												</Col>
												<Col lg="3">
													<FormGroup>
														<label className="form-control-label">Code Blocked</label>
														<Input
															className="form-control-alternative"
															name="code_blocked"
															placeholder="Code Blocked"
															type="text"
															value={form.code_blocked}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
											<Col lg="4">
													<FormGroup>
														<label className="form-control-label">Type Logic</label>
														<Input
															className="form-control-alternative"
															name="type_logic"
															type="select"
															onChange={this.formChange}
															value={form.type_logic}
														>
														<option value="and">And</option>
														<option value="or">Or</option>
														</Input>
													</FormGroup>
												</Col>
											<Col lg="8">
													<FormGroup>
														<label className="form-control-label">Description</label>
														<Input
															className="form-control-alternative"
															name="description"
															placeholder="Description"
															type="text"
															value={form.description}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
										</div>
										<table className="table">
											<thead>
												<tr>
												<th>Code</th>
												<th>Delete</th>
												</tr>
											</thead>
											<tbody>
												{conditions.map((item, index) => {
												return (
													<tr key={index}>
													<td>{item}</td>
													<td className="colum-pointer" onClick={() => deleteCondition(index)}> Drop</td>
													</tr>
												);
												})}
											</tbody>
										</table>
										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											{
												(conditions.length >0 ) ?
												<Button color="success" onClick={() => this.sendData(form, _id)}>
												{_id === undefined || _id === null ? "Create" : "Update"}
											</Button>
											: ""
											}
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Formulary;