import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title, entityCatSource } from "./config";
import { createItem, updateItem, getCategory, filterCode } from "utils/services";

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = { ...form };

		if (this.props._id !== null) {
			setForm = {
				code: this.props.form.code,
				tag_en: this.props.form.tag_en,
				tag_es: this.props.form.tag_es,
				description: this.props.form.description,
				txt_desc_en: this.props.form.txt_desc_en,
				txt_desc_es: this.props.form.txt_desc_es,
				tag_color: this.props.form.tag_color,
				tag_pattern: this.props.form.tag_pattern,
				tag_material: this.props.form.tag_material,
				tag_season: this.props.form.tag_season,
				avail: false,
				code_source: this.props.form.code_source,
				url_image: this.props.form.url_image,
				ind_availability: this.props.form.ind_availability,
				price_m2: this.props.form.price_m2
			};
		}

		this.state = {
			form: setForm,
			catSource: [],
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
		this.setData = this.setData.bind(this);
	}

	formChange(event) {
		let value = "";

		if(event.target.type === "select-one"){
			if (event.target.name === "code_source") {
				const {catSource} = this.state;
				catSource.forEach(cat => {
						if (cat._id === event.target.value) {
							value = cat;
							value.id = cat._id;
						}
				});
			}
		}else{
			value = event.target.value;
		}

		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: value
			}
		});
	}

	setData(entityCat, data){
		if (entityCat === "cat-source") {
			this.setState({
				catSource: data
			});
		}
	}

	sendData(form, _id) {
		const {data} = this.props;
		if (_id === null) {
			if(filterCode(data,form.code)){
				alert("The code entered has already been used.");
			}
			else{
				createItem(entity, form, this.props.setData);
		        this.props.changeView(false);
			}
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		    this.props.changeView(false);
		}
	}

	componentWillMount(){
		getCategory(entityCatSource, this.setData);
	}

	render() {
		const { _id, form, catSource } = this.state;

		const changeTypeElement = (event) => {
			this.setState({
				form: {
					...this.state.form,
					[event.target.name]: event.target.checked
				}
			})
		}

		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="3">
													<FormGroup>
														<label className="form-control-label" htmlFor="code">Code</label>
														<Input
															className="form-control-alternative"
															name="code"
															placeholder="Code"
															type="text"
															value={form.code}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="9">
													<FormGroup>
														<label className="form-control-label" htmlFor="description">Description</label>
														<Input
															className="form-control-alternative"
															name="description"
															placeholder="Description"
															type="text"
															value={form.description}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_en">Tag EN</label>
														<Input
															className="form-control-alternative"
															name="tag_en"
															placeholder="Tag EN"
															type="text"
															value={form.tag_en}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_es">Tag ES</label>
														<Input
															className="form-control-alternative"
															name="tag_es"
															placeholder="Tag ES"
															type="text"
															value={form.tag_es}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="txt_desc_en">TXT Description EN</label>
														<Input
															className="form-control-alternative"
															name="txt_desc_en"
															placeholder="Description EN"
															type="text"
															value={form.txt_desc_en}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="txt_desc_es">TXT Description ES</label>
														<Input
															className="form-control-alternative"
															name="txt_desc_es"
															placeholder="Description ES"
															type="text"
															value={form.txt_desc_es}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_color">Tag Color</label>
														<Input
															className="form-control-alternative"
															name="tag_color"
															placeholder="Tag Color"
															type="text"
															value={form.tag_color}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_pattern">Tag Pattern</label>
														<Input
															className="form-control-alternative"
															name="tag_pattern"
															placeholder="Tag Pattern"
															type="text"
															value={form.tag_pattern}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_material">Tag Material</label>
														<Input
															className="form-control-alternative"
															name="tag_material"
															placeholder="Tag Material"
															type="text"
															value={form.tag_material}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_season">Tag Season</label>
														<Input
															className="form-control-alternative"
															name="tag_season"
															placeholder="Tag Season"
															type="text"
															value={form.tag_season}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="source">Source</label>
														<Input
															type="select"
															name="code_source"
															value={form.code_source.id}
															onChange={this.formChange}
															className="form-control-alternative">
															<option value="0" disabled="disabled">Select Source</option>
															{
																catSource.map( source =>
																<option key={source._id} value={ source._id }>{source.code} - {source.tag_en}</option>
																)
															}
														</Input>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label">Url Image</label>
														<Input
															className="form-control-alternative"
															name="url_image"
															placeholder="Url Image"
															type="text"
															value={form.url_image}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label">Price M2</label>
														<Input
															className="form-control-alternative"
															name="price_m2"
															placeholder="Price M2"
															type="text"
															value={form.price_m2}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="2">
													<label className="form-control-label" htmlFor="source">Ind Availability</label>
													<label className="custom-toggle">
													<input type="checkbox" name="ind_availability" defaultChecked={form.ind_availability}  onChange={changeTypeElement}/>
													<span className="custom-toggle-slider rounded-circle" />
													</label>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											<Button color="success" onClick={() => this.sendData(form, _id)}>
												{_id === undefined || _id === null ? "Create" : "Update"}
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

			</>
		);
	}
}

export default Formulary;