import {
    nameEntity
} from "utils/constants";
import {deleteItem } from "utils/services";

export const entity = nameEntity.category;
export const entityGender = nameEntity.gender;

export const title = "Category";

export const form = {
    code: "",
    description: "",
    gender:{
        id: 0
    },
    countries:[
        {
            avaible: true,
            code: "en_US"
        },
        {
            avaible: true,
            code: "es_MX"
        },
        {
            avaible: true,
            code: "es_PA"
        }
    ],
    order: 0,
    url_image:""
};

export const columns  = (updateItem) => [ 
{
    dataField: "",
    text: "",
    attrs: { className: 'colum-pointer text-primary' },
    events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            updateItem(row);
        }
    },
    formatter: (cell, row) => {
        return "Edit";
    }
}, 
{
    dataField: "delete",
    text: "",
    attrs: { className: 'colum-pointer text-primary' },
    events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            var answer = window.confirm("Are you sure to delete this record");
            if (answer) {
                deleteItem(entity, row._id );
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
            else {
            }
        }
    },
    formatter: (cell, row) => {
        return "Delete";
    }
},
{
    dataField: 'code',
    text: 'Code',
    sort: true
}, {
    dataField: 'description',
    text: 'Description',
    sort: true
}, {
    dataField: 'createdAt',
    text: 'Created At',
    sort: true,
    formatter: (cell, row) => {
        var d = new Date(cell);
        return d.toLocaleString();
    }
}
];