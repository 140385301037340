import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title, entityGender } from "./config";
import { createItem, updateItem, getCategory,filterCode } from "utils/services";

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = {...form};

		if (this.props._id !== null) {
			setForm = {
				code : this.props.form.code,
				description : this.props.form.description,
				gender: this.props.form.gender,
				countries:this.props.form.countries,
				order: this.props.form.order,
				url_image: this.props.form.url_image
			};
		}

		this.state = {
			form: setForm,
			gender: [],
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
		this.setData = this.setData.bind(this);
	}

	formChange(event) {
		let value = "";

		if(event.target.type === "select-one"){
			if (event.target.name === "gender") {
				const {gender} = this.state;

				gender.forEach(gen => {
						if (gen._id === event.target.value) {
							value = gen;
							value.id = gen._id;
						}
				});
			}
		}else{
			value = event.target.value;
		}

		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: value
			}
		});

		console.log(this.state);
	}

	setData(entityCat, data){
		if (entityCat === "gender") {
			this.setState({
				gender: data
			});
		}
	}

	sendData(form, _id) {
		const {data} = this.props;
		if (_id === null) {
			if(filterCode(data,form.code)){
				alert("The code entered has already been used.");
			}
			else{
				createItem(entity, form, this.props.setData);
		        this.props.changeView(false);
			}
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		    this.props.changeView(false);
		}
	}

	componentWillMount(){
		getCategory(entityGender, this.setData);
	}

	render() {
		const { _id, form, gender } = this.state;

		const changeCountries = (event) => {

			/*const { form } = this.state;
			let item = {
				avaible:true,
				code:"en_US"
			};
			form.countries.push(item);*/

			if (event.target.name === "us") {
				form.countries[0].avaible = event.target.checked;
			}

			else if(event.target.name === "mx"){
				form.countries[1].avaible = event.target.checked;
			}

			else{
				form.countries[2].avaible = event.target.checked;
			}
		}
		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											{
												JSON.stringify(form.tag_season)

											}
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="code">Code</label>
														<Input
															className="form-control-alternative"
															name="code"
															placeholder="Code"
															type="text"
															value={form.code}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="description">Description</label>
														<Input
															className="form-control-alternative"
															name="description"
															placeholder="Description"
															type="text"
															value={form.description}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="order">Order</label>
														<Input
															className="form-control-alternative"
															name="order"
															placeholder="Order"
															type="text"
															value={form.order}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="url">Url Image</label>
														<Input
															className="form-control-alternative"
															name="url_image"
															placeholder="Url Image"
															type="text"
															value={form.url_image}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
											   <Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="gender">Gender</label>
														<Input
															type="select"
															name="gender"
															value={form.gender.id}
															onChange={this.formChange}
															className="form-control-alternative">
															<option value="0" disabled="disabled">Select Gender</option>
															{
																gender.map( item =>
																<option key={item._id} value={ item._id }>{item.code} - {item.tag_en}</option>
																)
															}
														</Input>
													</FormGroup>
												</Col>
												<Col lg="2">
													<label className="form-control-label" >USA</label>
													<br/>
													<label className="custom-toggle">
													<input type="checkbox" name="us" defaultChecked={form.countries[0].avaible} onChange={changeCountries}/>
													<span className="custom-toggle-slider rounded-circle" />
													</label>
												</Col>
												<Col lg="2">
													<label className="form-control-label">MEXICO</label>
													<br/>
													<label className="custom-toggle">
													<input type="checkbox" name="mx" defaultChecked={form.countries[1].avaible} onChange={changeCountries} />
													<span className="custom-toggle-slider rounded-circle" />
													</label>
												</Col>
												<Col lg="2">
													<label className="form-control-label">PANAMA</label>
													<br/>
													<label className="custom-toggle">
													<input type="checkbox" name="pa" defaultChecked={form.countries[2].avaible} onChange={changeCountries} />
													<span className="custom-toggle-slider rounded-circle" />
													</label>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											<Button color="success" onClick={() => this.sendData(form, _id)}>
												{_id === undefined || _id === null ? "Create" : "Update"}
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Formulary;