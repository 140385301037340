import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title } from "./config";
import { createItem, updateItem } from "utils/services";

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = { ...form };

		if (this.props._id !== null) {
			setForm = {
				code: this.props.form.code,
				tipo_descuento: this.props.form.tipo_descuento,
				valor: this.props.form.valor,
				productos: this.props.form.productos,
				description: this.props.form.description,
				all: this.props.form.all,
				fecha_vencimiento: this.props.form.fecha_vencimiento
			};
		}

		this.state = {
			form: setForm,
			productos: [],
			producto: "",
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.formChanges = this.formChanges.bind(this);
		this.sendData = this.sendData.bind(this);
	}

	formChange(event) {
		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: event.target.value
			}
		});
	}

	formChanges(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	sendData(form, _id) {
		if (_id === null) {
			createItem(entity, form, this.props.setData);
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		}

		this.props.changeView(false);
	}

	componentWillMount() {
		const { form } = this.state;
		this.setState({
			productos: form.productos
		})
	}

	render() {
		const { _id, form, productos, producto } = this.state;

		const addProducts = () => {
			productos.push(producto);
			this.setState({
				form: {
					productos: productos,
					...form
				}
			});
		};

		const deleteProducts = (index) => {
			productos.splice(index, 1);
			this.setState({
				form: {
					productos: productos,
					...form
				}
			});
		};

		const changeTypeElement = (event) => {
			this.setState({
				form: {
					...this.state.form,
					[event.target.name]: event.target.checked
				}
			})
		}
		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label">Code</label>
														<Input
															className="form-control-alternative"
															name="code"
															placeholder="Code"
															type="text"
															value={form.code}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label">Type Discount</label>
														<Input
															className="form-control-alternative"
															name="tipo_descuento"
															placeholder="Type Discount"
															type="select"
															value={form.tipo_descuento}
															onChange={this.formChange}
														>
															<option value="value">Value</option>
															<option value="percentage">Percentage</option>
														</Input>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label">Value</label>
														<Input
															className="form-control-alternative"
															name="valor"
															placeholder="Value"
															type="text"
															value={form.valor}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="8">
													<FormGroup>
														<label className="form-control-label">Description</label>
														<Input
															className="form-control-alternative"
															name="description"
															type="text"
															onChange={this.formChange}
															value={form.description}
														/>
													</FormGroup>
												</Col>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="source">All Products</label>
														<br/>
														<label className="custom-toggle">
															<input type="checkbox" name="all" defaultChecked={form.all} onChange={changeTypeElement} />
															<span className="custom-toggle-slider rounded-circle" />
														</label>
													</FormGroup>
												</Col>
												{form.all === false &&


													<Col lg="12">
														<Row>
															<Col lg="8">
																<FormGroup>
																	<label className="form-control-label">Products</label>
																	<Input
																		className="form-control-alternative"
																		name="producto"
																		placeholder="Products"
																		type="text"
																		onChange={this.formChanges}
																	/>
																</FormGroup>
															</Col>
															<Col lg="4">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">-</label>
																	<Button className="btn-block" color="" onClick={() => addProducts()}>Add</Button>
																</FormGroup>
															</Col>
														</Row>
													</Col>
												}
											</Row>
										</div>
										{form.all === false &&
											<table className="table">
												<thead>
													<tr>
														<th>Code</th>
														<th>Delete</th>
													</tr>
												</thead>
												<tbody>
													{productos.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item}</td>
																<td className="colum-pointer" onClick={() => deleteProducts(index)}> Drop</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										}

										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											{
												(productos.length > 0 || form.all === true) ?
													<Button color="success" onClick={() => this.sendData(form, _id)}>
														{_id === undefined || _id === null ? "Create" : "Update"}
													</Button>
													: ""
											}
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Formulary;