import {
    nameEntity
} from "utils/constants";
import {deleteItem } from "utils/services";

export const entity = nameEntity.products;
export const entityCategory = nameEntity.category;
export const entityCatSection = nameEntity.catSection;
export const entityCatElement = nameEntity.catElement;
export const entityCatOptions = nameEntity.catOption;
export const entityFabric = nameEntity.fabric;
export const entityCatSource = nameEntity.catSource;

export const title = "Product";

export const form = {
    code: "",
    code_source: "",
    num_m2: "",
    section_default: "",
    img_default: '',
    category: {
        _id: 0
    },
    countries:[
        {
            avalible: true,
            code: "en_US",
            name: "USA",
            base_price: 0,
            factor_fabric: 1,
            min_price: 0,
            max_price: 0
        },
        {
            avalible: true,
            code: "es_MX",
            name: "MEXICO",
            base_price: 0,
            factor_fabric: 1,
            min_price: 0,
            max_price: 0
        },
        {
            avalible: true,
            name: "Panama",
            code: "es_PA",
            base_price: 0,
            factor_fabric: 1,
            min_price: 0,
            max_price: 0
        }
    ],
    cat_section: [
    ]
};

export const columns = (updateItem) => [
    {
        dataField: "",
        text: "",
        attrs: {
            className: 'colum-pointer text-primary'
        },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                updateItem(row);
            }
        },
        formatter: (cell, row) => {
            return "Edit";
        }
    },
    {
        dataField: "delete",
        text: "",
        attrs: { className: 'colum-pointer text-primary' },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                var answer = window.confirm("Are you sure to delete this record");
                if (answer) {
                    deleteItem(entity, row._id );
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
                else {
                }
            }
        },
        formatter: (cell, row) => {
            return "Delete";
        }
    },
    {
        dataField: 'code',
        text: 'Code',
        sort: true
    }, {
        dataField: 'createdAt',
        text: 'Created At',
        sort: true,
        formatter: (cell, row) => {
            var d = new Date(cell);
            return d.toLocaleString();
        }
    }
];