import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title } from "./config";
import { createItem, updateItem } from "utils/services";
import SunEditor,{buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = { ...form };

		if (this.props._id !== null) {

			setForm = {
				code: this.props.form.code,
				section: this.props.form.section,
				device: this.props.form.device,
				country: this.props.form.country,
				texto: this.props.form.texto
			};
		}

		this.state = {
			form: setForm,
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	formChange(event) {
		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: event.target.value
			}
		});
	}

	sendData(form, _id) {
		if (_id === null) {
			createItem(entity, form, this.props.setData);
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		}

		this.props.changeView(false);
	}

	handleChange(content){
		this.setState({
			form: {
				...this.state.form,
				texto:content
			}
		});
	}

	render() {
		const { _id, form } = this.state;

		
		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="code">Code</label>
														<Input
															className="form-control-alternative"
															name="code"
															placeholder="Code"
															type="text"
															value={form.code}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="section">Seccion</label>
														<Input
															className="form-control-alternative"
															name="section"
															placeholder="Seccion"
															type="text"
															value={form.section}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											 
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="country">Pais</label>
														<Input
															type="select"
															name="country"
															value={form.country}
															onChange={this.formChange}
															className="form-control-alternative">
															<option value="" disabled="disabled">Seleccionar Pais</option>
															<option value="en_US">en_US</option>
															<option value="es_MX">es_MX</option>
															<option value="es_PA">es_PA</option>

														</Input>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="device">Dispositivo</label>
														<Input
															type="select"
															name="device"
															value={form.device}
															onChange={this.formChange}
															className="form-control-alternative">
															<option value="" >Seleccionar Dispositivo</option>
															<option value="mobile" >Mobile</option>
															<option value="desktop" >Desktop
															</option>
														</Input>
													</FormGroup>
												</Col>

												
											</Row>
											<Row>
												<Col lg="12">
													<SunEditor 
														name="texto"
														onChange={this.handleChange}
														setContents={form.texto}
														setOptions={{
														height: 200,
														buttonList: buttonList.complex
													}}/>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											<Button color="success" onClick={() => this.sendData(form, _id)}>
												{_id === undefined || _id === null ? "Create" : "Update"}
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

			</>
		);
	}
}

export default Formulary;