import {
   // urlApi,
    urlApiV2
} from "./constants";

export const getAll = (entity, setData) => {

    fetch(urlApiV2 + entity)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            if (data.success) {
                return setData({
                    data: data.data
                });
            }
            console.log("error al consultar");
        });
};

export const getCategory = (entity, setData) => {

    fetch(urlApiV2 + entity)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            if (data.success) {
                return setData(entity, data.data);
            }
            console.log("error al consultar");
        });
};

export const createItem = (entity, form, setData) => {
    let fetchOptions = {
        method: 'POST',
        body: JSON.stringify(form),
        headers: {
            'Content-Type': 'application/json'
        }
    };

    fetch(urlApiV2 + entity, fetchOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            if (data.success) {
                return getAll(entity, setData);
            }

            console.log("error al crear registro");
        });
};

export const updateItem = (entity, form, id, setData) => {
    let fetchOptions = {
        method: 'PUT',
        body: JSON.stringify(form),
        headers: {
            'Content-Type': 'application/json'
        }
    };

    fetch(urlApiV2 + entity + "/" + id, fetchOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            if (data.success) {
                return getAll(entity, setData);
            }

            console.log("error al actualizar el registro");
        });
};

export const deleteItem = (entity,id) => {
    let fetchOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    fetch(urlApiV2 + entity + "/" + id, fetchOptions)
        .then(function (response) {
            return response.json();
        })
        .then(data => console.log(data)) 
        .catch(err => console.log("error al borrar el registro " + err));
};

export const filterCode = (data, code) =>{

    let existe = false;

    data.forEach((item) => {
        if (item.code === code) {
            existe = true;
        }
    });

    return existe;
};