
import React from "react";
import {
    Card, CardHeader, CardFooter, Button, FormGroup,
    InputGroupAddon, InputGroupText, InputGroup} from "reactstrap";
import ToolkitProvider  from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


const optionPagination = {
    sizePerPage: 7,
    sizePerPageList: []
};
const defaultSorted = [{
    dataField: 'createdAt',
    order: 'asc'
}];

const SisGtSearch = (props) => {
    let input;
    const handleChange = () => {
        props.onSearch(input.value);
    };
    return (
        <input
            placeholder="Search"
            type="text"
            className="form-control"
            ref={n => input = n}
            onChange={handleChange}
        />
    );
};



class GridTable extends React.Component {
    render() {
        const { data, columns, title } = this.props;
        return (
            <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between">
                    <h3 className="mb-0">{title}</h3>
                    <Button color="primary" onClick={() => this.props.changeView(true)}>
                        Create
                </Button>
                </CardHeader>

                <ToolkitProvider keyField="id" data={data} columns={columns} search >
                    {
                        props => (
                            <div className="sis-table table-responsive">
                                <div className="sis-search navbar-search navbar-search-dark form-inline mr-3  ml-lg-auto">
                                    <FormGroup className="mb-0">
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <SisGtSearch {...props.searchProps} />
                                        </InputGroup>
                                    </FormGroup>
                                </div>

                                <BootstrapTable
                                    {...props.baseProps}
                                    defaultSorted={defaultSorted}
                                    pagination={paginationFactory(optionPagination)}
                                    hover={true}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>


                <CardFooter className="py-4">
                    <nav aria-label="..."> </nav>
                </CardFooter>
            </Card >
        );
    }
}

export default GridTable;