import React from "react";
import { Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import GridTable from "components/Common/GridTable";
import { getAll } from "utils/services";
import { columns, title, entity } from "./config";
import Formulary from "./Form";

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formView: false,
            form: {},
            _id: null,
            data: []
        };
        this.setData = this.setData.bind(this);
        this.changeView = this.changeView.bind(this);
		this.updateItem = this.updateItem.bind(this);
    }

	updateItem(data) {
        this.changeView(true);
        this.setState({form: data, _id: data._id});
    }

    setData(data) {
        this.setState(data);
    }

    changeView(view) {
        this.setState({formView: view, _id: null, form: {}});
    }

    componentWillMount() {
        getAll(entity, this.setData);
    }

    render() {
        const { data, formView, _id, form } = this.state;
        return (
            <>
                <Header />
                <Container className="mt--7" fluid>
                    {
                        formView ?
                            <Formulary
                                changeView={this.changeView}
                                setData={this.setData}
                                form={form}
                                _id={_id}
                            />
                            :
                            <Row>
                                <div className="col">
                                    <GridTable
                                        columns={columns(this.updateItem)}
                                        data={data}
                                        title={title}
                                        changeView={this.changeView}
                                    />
                                </div>
                            </Row>
                    }
                </Container>
            </>
        );
    }
}

export default List;
