import React from "react";
import queryString from 'query-string';
import {  Container } from "reactstrap";
import { urlCognito } from "utils/constants";
import Header from "components/Headers/Header.js";

class Index extends React.Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem("cognito-code") && localStorage.getItem("cognito-expire")) {
      let today = new Date(Date.now());
      let expire = localStorage.getItem("cognito-expire");
      if (expire <= today) {
        localStorage.setItem("cognito-code", "");
        localStorage.setItem("cognito-expire", "");
        window.location.href = urlCognito;
      }

    } else {
      let parameters = queryString.parse(this.props.location.search);
      if (parameters.code !== undefined) {
        localStorage.setItem("cognito-code", parameters.code);
        let nowPlus30Days = new Date(Date.now() + (30 * 24 * 60 * 60 * 1000));
        localStorage.setItem("cognito-expire", nowPlus30Days.getTime());
      } else {
        window.location.href = urlCognito;
      }
    }

    this.state = {
      activeNav: 1
    };

  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index
    });
  };
  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
        </Container>
      </>
    );
  }
}

export default Index;
