import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col, Table } from "reactstrap";
import { form, entity, title, entityCatElement, entityCatOptions, entityCatSection, entityCategory, entityFabric, entityCatSource } from "./config";
import { createItem, updateItem, getCategory } from "utils/services";
const typeTrue = { default: true, basic: true, intermediate: true, advanced: true };
class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = { ...form };

		if (this.props._id !== null) {
			console.log(this.props.form);
			setForm = {
				code: this.props.form.code,
				countries: this.props.form.countries,
				category: this.props.form.category,
				cat_section: this.props.form.cat_section,
				code_source: this.props.form.code_source || { _id: 0 },
				num_m2: this.props.form.num_m2 || "",
				section_default: this.props.form.section_default || "",
				img_default: this.props.form.img_default || ""
			};
		}


		this.state = {
			form: setForm,
			sectionSelected: { _id: 0, ...typeTrue },
			sectionEdit: { _id: 0 },
			optionEdit: { _id: 0 },
			optionSelection: { _id: 0, ...typeTrue },
			fabricSelection: { _id: 0, ...typeTrue },
			elementSelection: { _id: 0, ...typeTrue },
			catOptions: [],
			catElement: [],
			catSection: [],
			category: [],
			catSource: [],
			fabric: [],
			showSection: false,
			showElements: false,
			_id: this.props._id,
			message: ""
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
		this.setData = this.setData.bind(this);
		this.formChangeSection = this.formChangeSection.bind(this);
		this.sectionEditHandler = this.sectionEditHandler.bind(this);
		this.formChangeCountry = this.formChangeCountry.bind(this);
		this.onChangeOrderSections = this.onChangeOrderSections.bind(this);
	}

	sectionEditHandler(section) {
		this.setState({
			showSection: true,
			showElements: false,
			sectionEdit: section,
			optionEdit: { _id: 0 }
		});
	}

	formChangeCountry(event, pais) {
		let countries = this.state.form.countries;
		let value = event.target.value;

		if (event.target.type === "checkbox") {
			value = event.target.checked;
		}

		let data = {
			...pais,
			[event.target.name]: value
		};

		countries.forEach((element, index) => {
			if (data.code === element.code) {
				countries[index] = data;
			}
		});

		this.setState({
			form: {
				...this.state.form,
				countries: countries
			}
		});

	}

	optionEditHandler(option) {
		this.setState({
			showSection: true,
			showElements: true,
			optionEdit: option
		});

		this.state.sectionEdit.cat_option.sort(function (a, b) {
			if (a.code > b.code) {
				return 1;
			}
			if (a.code < b.code) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});
	}

	formChangeSection(event) {
		if (event.target.name === "cat_section") {
			const { catSection } = this.state;
			let value = {};
			catSection.forEach(cat => {
				if (cat._id === event.target.value) {
					value = cat;
					value.id = cat._id;
				}
			});
			this.setState({
				sectionSelected: { ...value, ...typeTrue }
			});
		}

		if (event.target.name === "cat_option") {
			const { catOptions } = this.state;
			let value = {};
			catOptions.forEach(cat => {
				if (cat._id === event.target.value) {
					value = cat;
					value.id = cat._id;
				}
			});
			this.setState({
				optionSelection: { ...value, ...typeTrue }
			});
		}

		if (event.target.name === "fabric") {
			const { fabric } = this.state;
			let value = {};
			fabric.forEach(cat => {
				if (cat._id === event.target.value) {
					value = cat;
					value.id = cat._id;
				}
			});
			this.setState({
				fabricSelection: value
			});
		}

		if (event.target.name === "cat_element") {
			const { catElement } = this.state;
			let value = {};
			catElement.forEach(cat => {
				if (cat._id === event.target.value) {
					value = cat;
					value.id = cat._id;
				}
			});
			this.setState({
				elementSelection: { ...value, ...typeTrue }
			});
		}

		if (event.target.name === "type_option") {
			this.setState({
				typeSelection: event.target.value
			});
		}
	}

	formChange(event) {
		let value = event.target.value;

		if (event.target.type === "select-one") {
			if (event.target.name === "category") {
				const { category } = this.state;

				category.forEach(cat => {
					if (cat._id === event.target.value) {
						value = cat;
						value.id = cat._id;
					}
				});
			}

			if (event.target.name === "code_source") {
				const { catSource } = this.state;

				catSource.forEach(cat => {
					if (cat._id === event.target.value) {
						value = cat;
						value.id = cat._id;
					}
				});
			}

		}

		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: value
			}
		});
	}

	onChangeOrderSections(section, event) {
		let data = {
			...section,
			order: event.target.value
		}

		let sections = this.state.form.cat_section;

		sections.forEach((cat, index) => {
			if (cat.id === data.id) {
				sections[index] = data;
			}
		});

		this.setState({
			form: {
				...this.state.form,
				cat_section: sections
			}
		});
	}

	onChangeOrderOptions(option, event) {
		let data = {
			...option,
			order: event.target.value
		}

		let options = this.state.sectionEdit.cat_option;

		options.forEach((opt, index) => {
			if (opt.id === data.id) {
				options[index] = data;
			}
		});

		this.setState({
			sectionEdit: {
				cat_option: options
			}
		});

		console.log(options);
	}

	setData(entityCat, data) {
		if (entityCat === "cat-section") {
			this.setState({
				catSection: data
			});
		}
		if (entityCat === "category") {
			this.setState({
				category: data
			});
		}

		if (entityCat === "cat-source") {
			this.setState({
				catSource: data
			});
		}

		if (entityCat === "cat-option") {
			this.setState({
				catOptions: data
			});
		}

		if (entityCat === "cat-element") {
			this.setState({
				catElement: data
			});
		}
		if (entityCat === "fabric") {
			this.setState({
				fabric: data
			});
		}
	}

	sendData(form, _id) {
		if (_id === null) {
			createItem(entity, form, this.props.setData);
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		}

		this.setState({
			message: "Successfully updated registration"
		});

		setTimeout(() => {
			this.setState({
				message: ""
			});
		}, 5000);

		//this.props.changeView(false);
	}

	componentWillMount() {
		getCategory(entityCatElement, this.setData);
		getCategory(entityCatOptions, this.setData);
		getCategory(entityCatSection, this.setData);
		getCategory(entityCategory, this.setData);
		getCategory(entityFabric, this.setData);
		getCategory(entityCatSource, this.setData);
	}

	render() {
		const {
			_id, form, category, catSource, catSection, sectionSelected, sectionEdit, showSection, fabric, optionEdit,
			catElement, catOptions, optionSelection, fabricSelection, elementSelection, showElements
		} = this.state;


		form.cat_section.sort(function (a, b) {
			if (a.code > b.code) {
				return 1;
			}
			if (a.code < b.code) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});

		category.sort(function (a, b) {
			if (a.code > b.code) {
				return 1;
			}
			if (a.code < b.code) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});

		catSection.sort(function (a, b) {
			if (a.code > b.code) {
				return 1;
			}
			if (a.code < b.code) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});

		catElement.sort(function (a, b) {
			if (a.code > b.code) {
				return 1;
			}
			if (a.code < b.code) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});

		catOptions.sort(function (a, b) {
			if (a.code > b.code) {
				return 1;
			}
			if (a.code < b.code) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});

		const changeTypeSection = (section, event) => {

			let data = {
				...section,
				[event.target.name]: event.target.checked
			}

			let sections = this.state.form.cat_section;
			console.log();
			sections.forEach((cat, index) => {
				if (cat._id !== undefined) {
					if (cat._id === data._id) {
						sections[index] = data;
					}
				} else if (cat.id === data.id) {
					sections[index] = data;
				}
			});

			console.log(sections);
			this.setState({
				form: {
					...this.state.form,
					cat_section: sections
				}
			});
		}

		const changeTypeOption = (option, event) => {
			let data = {
				...option,
				[event.target.name]: event.target.checked
			}

			let options = this.state.sectionEdit.cat_option || [];

			options.forEach((cat, index) => {
				if (cat._id !== undefined) {
					if (cat._id === data._id) {
						options[index] = data;
					}
				} else if (cat.id === data.id) {
					options[index] = data;
				}
			});

			let section = {
				...this.state.sectionEdit,
				cat_option: options
			};

			let cat_section = this.state.form.cat_section;

			cat_section.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === section._id) {
						cat_section[index] = section;
					}
				} else if (element.id === section.id) {
					cat_section[index] = section;
				}
			});

			this.setState({
				form: {
					...this.state.form,
					cat_section: cat_section
				},
				sectionEdit: section
			});
		}

		const changeTypeElement = (element, event) => {
			let data = {
				...element,
				[event.target.name]: event.target.checked
			}

			let elements = this.state.optionEdit.option_element || [];

			elements.forEach((cat, index) => {
				if (cat._id !== undefined) {
					if (cat._id === data._id) {
						elements[index] = data;
					}
				} else if (cat.id === data.id) {
					elements[index] = data;
				}
			});

			let option = {
				...this.state.optionEdit,
				option_element: elements
			};

			let options = this.state.sectionEdit.cat_option;

			options.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === option._id) {
						options[index] = option;
					}
				} else if (element.id === option.id) {
					options[index] = option;
				}
			});

			let section = {
				...this.state.sectionEdit,
				cat_option: options
			};

			let cat_section = this.state.form.cat_section;

			cat_section.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === section._id) {
						cat_section[index] = section;
					}
				}
				else if (element.id === section.id) {
					cat_section[index] = section;
				}
			});

			this.setState({
				form: {
					...this.state.form,
					cat_section: cat_section
				},
				sectionEdit: section,
				optionEdit: option
			});
		}

		const addElement = (selectElement) => {
			let element = this.state.optionEdit.option_element || [];
			let flag = true;

			element.forEach(cat => {
				if (cat._id !== undefined) {
					if (cat._id === selectElement._id) {
						flag = false;
					}
				} else if (cat.id === selectElement.id) {
					flag = false;
				}

			});

			if (flag === false) {
				return alert("the selected element has already been added");
			}

			element.push(selectElement);

			let option = {
				...this.state.optionEdit,
				option_element: element
			};

			let options = this.state.sectionEdit.cat_option;

			options.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === option._id) {
						options[index] = option;
					}
				} else if (element.id === option.id) {
					options[index] = option;
				}
			});

			let section = {
				...this.state.sectionEdit,
				cat_option: options
			};

			let cat_section = this.state.form.cat_section;

			cat_section.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === section._id) {
						cat_section[index] = section;
					}
				} else if (element.id === section.id) {
					cat_section[index] = section;
				}
			});

			this.setState({
				form: {
					...this.state.form,
					cat_section: cat_section
				},
				sectionEdit: section,
				optionEdit: option
			});

		}

		const addOption = (selectOption) => {
			let options = this.state.sectionEdit.cat_option || [];
			let flag = true;

			options.forEach(cat => {
				if (cat._id !== undefined) {
					if (cat._id === selectOption._id) {
						flag = false;
					}
				} else if (cat.id === selectOption.id) {
					flag = false;
				}
			});

			if (flag === false) {
				return alert("the selected option has already been added");
			}

			options.push(selectOption);

			let section = {
				...this.state.sectionEdit,
				cat_option: options
			};

			let cat_section = this.state.form.cat_section;

			cat_section.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === section._id) {
						cat_section[index] = section;
					}
				}
				if (element.id === section.id) {
					cat_section[index] = section;
				}
			});

			this.setState({
				form: {
					...this.state.form,
					cat_section: cat_section
				},
				sectionEdit: section
			});
		}

		const addSection = () => {
			let sections = this.state.form.cat_section;
			let flag = true;

			sections.forEach(cat => {
				if (cat._id !== undefined) {
					if (cat._id === sectionSelected._id) {
						flag = false;
					}
				}
				if (cat.id === sectionSelected.id) {
					flag = false;
				}
			});

			if (flag) {
				sections.push(sectionSelected);
				this.setState({
					form: {
						...this.state.form,
						cat_section: sections
					}
				});
			} else {
				alert("the selected section has already been added");
			}

		}

		const removeSection = (section) => {
			let sections = this.state.form.cat_section;
			const index = sections.indexOf(section);

			if (index > -1) {
				sections.splice(index, 1);
				this.setState({
					form: {
						...this.state.form,
						cat_section: sections
					}
				});
			}
		}

		const removeOption = (option) => {
			let options = this.state.sectionEdit.cat_option || [];
			const index = options.indexOf(option);
			options.splice(index, 1);

			let section = {
				...this.state.sectionEdit,
				cat_option: options
			};

			let cat_section = this.state.form.cat_section;

			cat_section.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === section._id) {
						cat_section[index] = section;
					}
				} else if (element.id === section.id) {
					cat_section[index] = section;
				}
			});

			this.setState({
				form: {
					...this.state.form,
					cat_section: cat_section
				},
				sectionEdit: section
			});
		}

		const removeElement = (selectElement) => {
			let element = this.state.optionEdit.option_element || [];
			const index = element.indexOf(selectElement);
			element.splice(index, 1);

			let option = {
				...this.state.optionEdit,
				option_element: element
			};

			let options = this.state.sectionEdit.cat_option;

			options.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === option._id) {
						options[index] = option;
					}
				} else if (element.id === option.id) {
					options[index] = option;
				}
			});

			let section = {
				...this.state.sectionEdit,
				cat_option: options
			};

			let cat_section = this.state.form.cat_section;

			cat_section.forEach((element, index) => {
				if (element._id !== undefined) {
					if (element._id === option._id) {
						options[index] = option;
					}
				} else if (element.id === section.id) {
					cat_section[index] = section;
				}
			});

			this.setState({
				form: {
					...this.state.form,
					cat_section: cat_section
				},
				sectionEdit: section,
				optionEdit: option
			});

		}

		return (
			<>
				<Container className="mt--12" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="12">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="12">
													<Row>
														<Col lg="4">
															<FormGroup>
																<label className="form-control-label" htmlFor="code">Code</label>
																<Input
																	className="form-control-alternative"
																	name="code"
																	placeholder="Code"
																	type="text"
																	value={form.code}
																	onChange={this.formChange}
																/>
															</FormGroup>
														</Col>

														<Col lg="8">
															<FormGroup>
																<label className="form-control-label" htmlFor="source">Category</label>
																<Input
																	type="select"
																	name="category"
																	value={form.category.id}
																	onChange={this.formChange}
																	className="form-control-alternative">
																	<option value="0" disabled="disabled">Select Category</option>
																	{
																		category.map(source =>
																			<option key={`category-${source._id}`} value={source._id}>{source.code} - {source.description}</option>
																		)
																	}
																</Input>
															</FormGroup>
														</Col>
													</Row>
												</Col>
												<Col lg="12">
													<Row>
														<Col lg="4">
															<FormGroup>
																<label className="form-control-label" htmlFor="num_m2">Num M2</label>
																<Input
																	className="form-control-alternative"
																	name="num_m2"
																	placeholder="Num M2"
																	type="text"
																	value={form.num_m2}
																	onChange={this.formChange}
																/>
															</FormGroup>
														</Col>

														<Col lg="8">
															<FormGroup>
																<label className="form-control-label" htmlFor="code_source">Code Source</label>
																<Input
																	type="select"
																	name="code_source"
																	value={form.code_source._id}
																	onChange={this.formChange}
																	className="form-control-alternative">
																	<option value="0" disabled="disabled">Select Source</option>
																	{
																		catSource.map(source =>
																			<option key={source._id} value={source._id}>{source.code} - {source.tag_en}</option>
																		)
																	}
																</Input>
															</FormGroup>
														</Col>
													</Row>
												</Col>

												<Col lg="12">
													<Row>
														<Col lg="4">
															<FormGroup>
																<label className="form-control-label" htmlFor="section_default">Section default</label>
																<Input
																	className="form-control-alternative"
																	name="section_default"
																	placeholder="Section default"
																	type="text"
																	value={form.section_default}
																	onChange={this.formChange}
																/>
															</FormGroup>
														</Col>

														<Col lg="8">
															<FormGroup>
																<label className="form-control-label" htmlFor="img_default">Img default</label>
																<Input
																	className="form-control-alternative"
																	name="img_default"
																	placeholder="Img default"
																	type="text"
																	value={form.img_default}
																	onChange={this.formChange}
																/>
															</FormGroup>
														</Col>
													</Row>
												</Col>
											</Row>
											<hr />
											<Row>
												<Col lg="12">
													<Table>
														<thead className="thead-light">
															<tr>
																<th scope="col">Country</th>
																<th scope="col">Avalible</th>
																<th scope="col">Base Price</th>
																<th scope="col">Factor Fabric</th>
																<th scope="col">Min Price</th>
																<th scope="col">Max Price</th>
															</tr>
														</thead>
														<tbody>
															{
																form.countries.map((pais) =>
																	<tr key={pais.code}>
																		<td >
																			{pais.name}
																		</td>
																		<td>
																			<label className="custom-toggle">
																				<input type="checkbox" name="avalible" defaultChecked={pais.avalible} onChange={(event) => this.formChangeCountry(event, pais)} />
																				<span className="custom-toggle-slider rounded-circle" />
																			</label>
																		</td>
																		<td>
																			<Input
																				className="form-control-alternative"
																				name="base_price"
																				type="text"
																				value={pais.base_price}
																				onChange={(event) => this.formChangeCountry(event, pais)}
																			/>
																		</td>
																		<td>
																			<Input
																				className="form-control-alternative"
																				name="factor_fabric"
																				type="text"
																				value={pais.factor_fabric}
																				onChange={(event) => this.formChangeCountry(event, pais)}
																			/>
																		</td>
																		<td>
																			<Input
																				className="form-control-alternative"
																				name="min_price"
																				type="text"
																				value={pais.min_price}
																				onChange={(event) => this.formChangeCountry(event, pais)}
																			/>
																		</td>
																		<td>
																			<Input
																				className="form-control-alternative"
																				name="max_price"
																				type="text"
																				value={pais.max_price}
																				onChange={(event) => this.formChangeCountry(event, pais)}
																			/>
																		</td>
																	</tr>
																)
															}
														</tbody>
													</Table>
												</Col>
											</Row>
											<hr />
											<Row>
												<Col lg="12">
													<Row>
														<Col lg="8">
															<FormGroup>
																<label className="form-control-label" htmlFor="source">Sections</label>
																<Input
																	type="select"
																	value={sectionSelected._id}
																	onChange={this.formChangeSection}
																	name="cat_section"
																	className="form-control-alternative">
																	<option value="0" disabled="disabled">Select section</option>
																	{
																		catSection.map(source =>
																			<option key={source._id} value={source._id}>{source.code} - {source.tag_en}</option>
																		)
																	}
																</Input>
															</FormGroup>
														</Col>
														<Col lg="4">
															<FormGroup>
																<label className="form-control-label" htmlFor="source">-</label>
																<Button className="btn-block" color="" onClick={() => addSection()}>Add</Button>
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col lg="12">
															<Table>
																<thead className="thead-light">
																	<tr>
																		<th scope="col">#</th>
																		<th scope="col">#</th>
																		<th scope="col">Code</th>
																		<th scope="col">Section</th>
																		<th scope="col">order</th>
																		<th scope="col">Default</th>
																		<th scope="col">Basic</th>
																		<th scope="col">Intermediate</th>
																		<th scope="col">Advanced</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		form.cat_section.sort().map((section, index) =>
																			<tr key={`${section._id}-${index}-section`} className={section.id === sectionEdit.id ? "text-primary" : ""}>

																				<td>
																					<i className="colum-pointer section" onClick={() => this.sectionEditHandler(section)}>
																						Edit
																					</i>
																				</td>
																				<td>
																					<i className="colum-pointer section" onClick={() => removeSection(section)}>
																						Delete
																					</i>
																				</td>
																				<td>
																					{section.code}
																				</td>
																				<td>
																					{section.tag_en}
																				</td>
																				<td>
																					<Input
																						value={section.order}
																						onChange={(event) => this.onChangeOrderSections(section, event)}
																						name="order"
																						className="form-control-alternative">
																					</Input>
																				</td>
																				<td className="text-center">
																					<label className="custom-toggle">
																						<input type="checkbox" name="default" defaultChecked={section.default} onChange={(event) => changeTypeSection(section, event)} />
																						<span className="custom-toggle-slider rounded-circle" />
																					</label>
																				</td>
																				<td className="text-center">
																					<label className="custom-toggle">
																						<input type="checkbox" name="basic" defaultChecked={section.basic} onChange={(event) => changeTypeSection(section, event)} />
																						<span className="custom-toggle-slider rounded-circle" />
																					</label>
																				</td>
																				<td className="text-center">
																					<label className="custom-toggle">
																						<input type="checkbox" name="intermediate" defaultChecked={section.intermediate} onChange={(event) => changeTypeSection(section, event)} />
																						<span className="custom-toggle-slider rounded-circle" />
																					</label>
																				</td>
																				<td className="text-center">
																					<label className="custom-toggle">
																						<input type="checkbox" name="advanced" defaultChecked={section.advanced} onChange={(event) => changeTypeSection(section, event)} />
																						<span className="custom-toggle-slider rounded-circle" />
																					</label>
																				</td>
																			</tr>
																		)
																	}
																</tbody>
															</Table>
														</Col>
													</Row>
												</Col>

												<Col lg="12">
													{
														(showSection && sectionEdit.tag_en !== "Fabric") &&
														<Row>
															<Col lg="8">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">Options</label>
																	<Input
																		type="select"
																		value={optionSelection._id}
																		onChange={this.formChangeSection}
																		name="cat_option"
																		className="form-control-alternative">
																		<option value="0" disabled="disabled">Select Option</option>
																		{
																			catOptions.map(source =>
																				<option key={source._id} value={source._id}>{source.code} - {source.tag_en}</option>
																			)
																		}
																	</Input>
																</FormGroup>
															</Col>
															<Col lg="4">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">-</label>
																	<Button className="btn-block" color="" onClick={() => addOption(optionSelection)}>Add</Button>
																</FormGroup>
															</Col>
														</Row>
													}
													{sectionEdit.tag_en === "Fabric" &&
														<Row>
															<Col lg="8">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">Fabric</label>
																	<Input
																		type="select"
																		value={fabricSelection._id}
																		onChange={this.formChangeSection}
																		name="fabric"
																		className="form-control-alternative">
																		<option value="0" disabled="disabled">Select Element</option>
																		{
																			fabric.map(source =>
																				<option key={source._id} value={source._id}>{source.code} - {source.group_name}</option>
																			)
																		}
																	</Input>
																</FormGroup>
															</Col>

															<Col lg="4">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">-</label>
																	<Button className="btn-block" color="" onClick={() => addOption(fabricSelection)}>Add</Button>
																</FormGroup>
															</Col>
														</Row>
													}
													{
														showSection &&
														<Row className="form-product">
															<Col lg="12">
																<Table>
																	<thead className="thead-light">
																		<tr>
																			<th scope="col">#</th>
																			<th scope="col">#</th>
																			<th scope="col">Code</th>
																			<th scope="col">Value</th>
																			<th scope="col">Order</th>
																			<th scope="col">Default</th>
																			<th scope="col">Basic</th>
																			<th scope="col">Intermediate</th>
																			<th scope="col">Advanced</th>
																		</tr>
																	</thead>
																	<tbody>
																		{sectionEdit.cat_option &&
																			sectionEdit.cat_option.map((option, index) =>
																				<tr key={`${option._id}-${index}-op`} className={option.id === optionEdit.id ? "text-primary" : ""}>
																					<td>
																						{
																							sectionEdit.tag_en !== "Fabric" &&
																							<i className="colum-pointer section" onClick={() => this.optionEditHandler(option)}>
																								Edit
																							</i>
																						}
																					</td>
																					<td>
																						<i className="colum-pointer section" onClick={() => removeOption(option)}>
																							Delete
																						</i>
																					</td>
																					<td>{option.code}</td>
																					<td> {sectionEdit.tag_en === "Fabric" ? option.group_name : option.tag_en} </td>
																					<td>
																						<Input
																							value={option.order}
																							onChange={(event) => this.onChangeOrderOptions(option, event)}
																							name="orderOptions"
																							className="form-control-alternative">
																						</Input>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="default" defaultChecked={option.default} onChange={(event) => changeTypeOption(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="basic" defaultChecked={option.basic} onChange={(event) => changeTypeOption(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="intermediate" defaultChecked={option.intermediate} onChange={(event) => changeTypeOption(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="advanced" defaultChecked={option.advanced} onChange={(event) => changeTypeOption(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>

																				</tr>
																			)
																		}
																	</tbody>
																</Table>
															</Col>
														</Row>
													}
												</Col>
												<Col lg="12">
													{showElements && showSection &&
														<Row>
															<Col lg="8">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">Element</label>
																	<Input
																		type="select"
																		value={elementSelection._id}
																		onChange={this.formChangeSection}
																		name="cat_element"
																		className="form-control-alternative">
																		<option value="0" disabled="disabled">Select Element</option>
																		{
																			catElement.map(source =>
																				<option key={source._id} value={source._id}>{source.code} - {source.tag_en}</option>
																			)
																		}
																	</Input>
																</FormGroup>
															</Col>

															<Col lg="4">
																<FormGroup>
																	<label className="form-control-label" htmlFor="source">-</label>
																	<Button className="btn-block" color="" onClick={() => addElement(elementSelection)}>Add</Button>
																</FormGroup>
															</Col>
														</Row>
													}

													{
														showSection && showElements &&
														<Row className="form-product">
															<Col lg="12">
																<Table>
																	<thead className="thead-light">
																		<tr>
																			<th scope="col">#</th>
																			<th scope="col">#</th>
																			<th scope="col">Code</th>
																			<th scope="col">Value</th>
																			<th scope="col">Default</th>
																			<th scope="col">Basic</th>
																			<th scope="col">Intermediate</th>
																			<th scope="col">Advanced</th>
																		</tr>
																	</thead>
																	<tbody>
																		{optionEdit.option_element &&
																			optionEdit.option_element.map((option, index) =>
																				<tr key={`${option._id}-${index}-element`} >
																					<td></td>
																					<td>
																						<i className="colum-pointer section" onClick={() => removeElement(option)}>
																							Delete
																						</i>
																					</td>
																					<td>{option.code}</td>
																					<td>{option.tag_en}</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="default" defaultChecked={option.default} onChange={(event) => changeTypeElement(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="basic" defaultChecked={option.basic} onChange={(event) => changeTypeElement(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="intermediate" defaultChecked={option.intermediate} onChange={(event) => changeTypeElement(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																					<td className="text-center">
																						<label className="custom-toggle">
																							<input type="checkbox" name="advanced" defaultChecked={option.advanced} onChange={(event) => changeTypeElement(option, event)} />
																							<span className="custom-toggle-slider rounded-circle" />
																						</label>
																					</td>
																				</tr>
																			)
																		}
																	</tbody>
																</Table>
															</Col>
														</Row>
													}
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										<div className="d-flex justify-content-start">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											<Button color="" onClick={() => this.props.changeView(false)}>Regresar</Button>
											<Button color="success" onClick={() => this.sendData(form, _id)}>
												{_id === undefined || _id === null ? "Create" : "Update"}
											</Button>
											<label className="badge badge-success">{this.state.message}</label>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

			</>
		);
	}
}

export default Formulary;