import {
    nameEntity
} from "utils/constants";
import {
    deleteItem
} from "utils/services";

export const entity = nameEntity.discounts;
export const title = "Table Discounts";

export const form = {
    code: "",
    tipo_descuento: "value",
    valor: "",
    productos: [],
    all: false,
    description: "",
    fecha_vencimiento: ""
};

export const columns = (updateItem) => [{
        dataField: "",
        text: "",
        attrs: {
            className: 'colum-pointer text-primary'
        },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                updateItem(row);
            }
        },
        formatter: (cell, row) => {
            return "Edit";
        }
    },
    {
        dataField: "delete",
        text: "",
        attrs: {
            className: 'colum-pointer text-primary'
        },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                var answer = window.confirm("Are you sure to delete this record");
                if (answer) {
                    deleteItem(entity, row._id);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {}
            }
        },
        formatter: (cell, row) => {
            return "Delete";
        }
    },
    {
        dataField: 'code',
        text: 'Code',
        sort: true
    }, {
        dataField: 'tipo_descuento',
        text: 'Type Discount',
        sort: true
    },
    {
        dataField: 'valor',
        text: 'Value',
        sort: true
    },
    {
        dataField: 'all',
        text: 'All Products',
        sort: false
    },
    {
        dataField: 'createdAt',
        text: 'Created At',
        sort: true,
        formatter: (cell, row) => {
            var d = new Date(cell);
            return d.toLocaleString();
        }
    }
];