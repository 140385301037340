/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index";
import CatSource from "views/cat-source/List";
import CatOption from "views/cat-option/List";
import CatElement from "views/cat-element/List";
import CatFabric from "views/cat-fabric/List";
import CatSection from "views/cat-section/List";
import Category from "views/category/List";
import Fabric from "views/fabric/List";
import Product from "views/product/List";
import Gender from "views/gender/List";
import TableIcompatibility from "views/table-incompatibility/List";
import Conversion from "views/conversion/List";
import Descuentos from "views/descuentos/List";
import Section from "views/sections/List";
import SectionText from "views/sections-text/List";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/cat-source",
    name: "Category Source",
    icon: "ni ni-bullet-list-67 text-red",
    component: CatSource,
    layout: "/admin"
  },
  {
    path: "/cat-option",
    name: "Category Option",
    icon: "ni ni-bullet-list-67 text-red",
    component: CatOption,
    layout: "/admin"
  },
  {
    path: "/cat-element",
    name: "Category Element",
    icon: "ni ni-bullet-list-67 text-red",
    component: CatElement,
    layout: "/admin"
  },
  {
    path: "/cat-fabric",
    name: "Category Fabric",
    icon: "ni ni-bullet-list-67 text-red",
    component: CatFabric,
    layout: "/admin"
  },
  {
    path: "/cat-section",
    name: "Category Section",
    icon: "ni ni-bullet-list-67 text-red",
    component: CatSection,
    layout: "/admin"
  },
  {
    path: "/gender",
    name: "Gender",
    icon: "ni ni-bullet-list-67 text-red",
    component: Gender,
    layout: "/admin"
  },
  {
    path: "/category",
    name: "Category",
    icon: "ni ni-bullet-list-67 text-red",
    component: Category,
    layout: "/admin"
  },
  {
    path: "/fabric",
    name: "Fabric",
    icon: "ni ni-bullet-list-67 text-red",
    component: Fabric,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-bullet-list-67 text-red",
    component: Product,
    layout: "/admin"
  },
  {
    path: "/table-incompatibility",
    name: "Table Incompatibility",
    icon: "ni ni-bullet-list-67 text-red",
    component: TableIcompatibility,
    layout: "/admin"
  },
  {
    path: "/conversion",
    name: "Conversion",
    icon: "ni ni-bullet-list-67 text-red",
    component: Conversion,
    layout: "/admin"
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "ni ni-bullet-list-67 text-red",
    component: Descuentos,
    layout: "/admin"
  },
  {
    path: "/sections",
    name: "Sections Front",
    icon: "ni ni-bullet-list-67 text-red",
    component: Section,
    layout: "/admin"
  }
  ,
  {
    path: "/sections-text",
    name: "Sections Text",
    icon: "ni ni-bullet-list-67 text-red",
    component: SectionText,
    layout: "/admin"
  }
];

export default routes;
