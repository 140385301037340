import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title, entityCatFabric } from "./config";
import { createItem, updateItem, getCategory } from "utils/services";

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = { ...form };

		if (this.props._id !== null) {
			setForm = {
				code: this.props.form.code,
				group_name: this.props.form.group_name,
				desc_group: this.props.form.desc_group,
				cat_fabric: this.props.form.cat_fabric
			};
		}

		this.state = {
			form: setForm,
			catFabric: [],
			categories:[],
			selectCategory:"",
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
		this.setData = this.setData.bind(this);
	}

	formChange(event) {
		let value = "";

		if(event.target.type === "select-one"){
			if (event.target.name === "cat_fabric") {
				const {catFabric} = this.state;
				catFabric.forEach(cat => {
						if (cat._id === event.target.value) {
							value = cat;
							value.id = cat._id;
						}
				});

				this.setState({
					selectCategory : value
				});
			}
		}else{
			value = event.target.value;
		}

		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: value
			}
		});
	}

	setData(entityCat, data){
		if (entityCat === "cat-fabric") {
			this.setState({
				catFabric: data
			});
		}
	}

	sendData(form, _id) {
		if (_id === null) {
			createItem(entity, form, this.props.setData);
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		}

		this.props.changeView(false);
	}

	componentWillMount(){
		getCategory(entityCatFabric, this.setData);

			const {form,selectCategory} = this.state;
			this.setState({
				categories: form.cat_fabric,
				selectCategory : selectCategory
			});
	}

	render() {
		const { _id, form, catFabric,categories,selectCategory } = this.state;

		const addCategory = () => {
			categories.push(selectCategory);
			this.setState({
				form : {
					code: form.code,
					group_name: form.group_name,
					desc_group: form.desc_group,
					cat_fabric: categories
				}
			});
		}

		const deleteCategory = (index) => {
			categories.splice(index,1);
			this.setState({
				form : {
					code: form.code,
					group_name: form.group_name,
					desc_group: form.desc_group,
					cat_fabric: categories
				}
			});
		}

		catFabric.sort(function(a,b){
			if (a.code > b.code) {
				return 1;
			  }
			  if (a.code < b.code) {
				return -1;
			  }
			  // a must be equal to b
			  return 0;
		});

		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="code">Code</label>
														<Input
															className="form-control-alternative"
															name="code"
															placeholder="Code"
															type="text"
															value={form.code}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="group_name">Group Name</label>
														<Input
															className="form-control-alternative"
															name="group_name"
															placeholder="Group Name"
															type="text"
															value={form.group_name}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="4">
													<FormGroup>
														<label className="form-control-label" htmlFor="desc_group">Group Description</label>
														<Input
															className="form-control-alternative"
															name="desc_group"
															placeholder="Group Description"
															type="text"
															value={form.desc_group}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="source">Category</label>
														<Input
															type="select"
															name="cat_fabric"
															onChange={this.formChange}
															className="form-control-alternative">
															<option value="0" >Select Category</option>
															{
																catFabric.map( (source,index) =>
																<option key={index} value={ source._id }>{source.code} - {source.tag_en}</option>
																)
															}
														</Input>
													</FormGroup>
												</Col>
												<Col lg="2">
															<FormGroup>
																<label className="form-control-label" htmlFor="source">-</label>
																<Button disabled ={(this.state.selectCategory === "") ? true : false} className="btn-block" onClick={() => addCategory()}>Add</Button>
															</FormGroup>
														</Col>
											</Row>
											<table className="table">
												<thead>
													<tr>
													<th>Code</th>
													<th>Delete</th>
													</tr>
												</thead>
												<tbody>
													{categories.map((item, index) => {
													return (
														<tr key={index}>
														<td>{item.code}</td>
														<td className="colum-pointer" onClick={()=> deleteCategory(index)}> Drop</td>
														</tr>
													);
													})}
												</tbody>
											</table>
										</div>
										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											{
												(categories.length > 0) ?
												<Button color="success" onClick={() => this.sendData(form, _id)}>
												{_id === undefined || _id === null ? "Create" : "Update"}
												</Button> :
												""
											}
											
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

			</>
		);
	}
}

export default Formulary;