const {
    REACT_APP_COGNITO_HOST,
    REACT_APP_COGNITO_CLIENT_ID,
    REACT_APP_API_V1_URL,
    REACT_APP_API_V2_URL
} = process.env;

let cognitoP = {
    redirect: window.location.origin + "/admin/index",
    host: REACT_APP_COGNITO_HOST,
    params: `?client_id=${REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=`
};

export const urlCognito = cognitoP.host + cognitoP.params + cognitoP.redirect;

//production url
export const urlApi = REACT_APP_API_V1_URL;
export const urlApiV2 = REACT_APP_API_V2_URL;

//local url
//export const urlApi = "http://localhost:3000/v1/";

export const nameEntity = {
    category: "category",
    catSection: "cat-section",
    catSource: "cat-source",
    catOption: "cat-option",
    catElement: "cat-element",
    catFabric: "cat-fabric",
    fabric: "fabric",
    products: "products",
    gender: "gender",
    tableIcompatibility: "table-incompatibility",
    conversion: "conversion",
    discounts: "descuentos",
    sections: "section",
    sectionsText: "section-text"
};