import {
    nameEntity
} from "utils/constants";
import {deleteItem } from "utils/services";

export const entity = nameEntity.catSection;
export const title = "Category Section";

export const form = {
    code: "",
    tag_en: "",
    tag_es: "",
    description: "",
    order: 0
};

export const columns  = (updateItem) => [ 
 {
   dataField: "",
   text: "",
   attrs: { className: 'colum-pointer text-primary' },
   events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            updateItem(row);
           }
   },
     formatter: (cell, row) => {
         return "Edit";
  }
},
{
    dataField: "delete",
    text: "",
    attrs: { className: 'colum-pointer text-primary' },
    events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            var answer = window.confirm("Are you sure to delete this record");
            if (answer) {
                deleteItem(entity, row._id );
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
            else {
            }
        }
    },
    formatter: (cell, row) => {
        return "Delete";
    }
},
    {
    dataField: 'code',
    text: 'Code',
    sort: true
}, {
    dataField: 'tag_en',
    text: 'Tag EN',
    sort: true
}, {
    dataField: 'tag_es',
    text: 'Tag ES',
    sort: true
}, {
    dataField: 'createdAt',
    text: 'Created At',
    sort: true,
    formatter: (cell, row) => {
        var d = new Date(cell);
        return d.toLocaleString();
    }
}
];