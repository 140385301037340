import {
    nameEntity
} from "utils/constants";
import {deleteItem } from "utils/services";

export const entity = nameEntity.catFabric;
export const entityCatSource = nameEntity.catSource;

export const title = "Category Fabric";

export const form = {
    code: "",
    tag_en: "",
    tag_es: "",
    description: "",
    tag_color:"",
    tag_pattern: "",
    tag_material:"",
    tag_season:"",
    txt_desc_en: "",
    txt_desc_es: "",
    avail: false,
    code_source: {
        id: 0
    },
    url_image:"",
    ind_availability:false,
    price_m2:""
};

export const columns = (updateItem) => [
    {
        dataField: "",
        text: "",
        attrs: {
            className: 'colum-pointer text-primary'
        },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                updateItem(row);
            }
        },
        formatter: (cell, row) => {
            return "Edit";
        }
    },
    {
        dataField: "delete",
        text: "",
        attrs: { className: 'colum-pointer text-primary' },
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                var answer = window.confirm("Are you sure to delete this record");
                if (answer) {
                    deleteItem(entity, row._id );
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
                else {
                }
            }
        },
        formatter: (cell, row) => {
            return "Delete";
        }
    },
    {
        dataField: 'code',
        text: 'Code',
        sort: true
    }, {
        dataField: 'txt_desc_en',
        text: 'TXT description',
        sort: true
    }, {
        dataField: 'tag_en',
        text: 'Tag',
        sort: true
    }, {
        dataField: 'code_source.code',
        text: 'Source Code',
        sort: true
    }, {
        dataField: 'code_source.tag_en',
        text: 'Source Tag',
        sort: true
    }, {
        dataField: 'code_source.description',
        text: 'Source Description',
        sort: true
    }, {
        dataField: 'createdAt',
        text: 'Created At',
        sort: true,
        formatter: (cell, row) => {
            var d = new Date(cell);
            return d.toLocaleString();
        }
    }
];