import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { form, entity, title } from "./config";
import { createItem, updateItem } from "utils/services";

class Formulary extends React.Component {

	constructor(props) {
		super(props);
		let setForm = {...form};

		if (this.props._id !== null) {
			setForm = {
				code : this.props.form.code,
				tag_en : this.props.form.tag_en,
				tag_es : this.props.form.tag_es,
				description : this.props.form.description
			};
		}

		this.state = {
			form: setForm,
			_id: this.props._id
		};

		this.formChange = this.formChange.bind(this);
		this.sendData = this.sendData.bind(this);
	}

	formChange(event) {
		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: event.target.value
			}
		});
	}

	sendData(form, _id) {
		if (_id === null) {
			createItem(entity, form, this.props.setData);
		}
		else if (_id !== null) {
			updateItem(entity, form, _id, this.props.setData);
		}

		this.props.changeView(false);
	}

	render() {
		const { _id, form } = this.state;
		return (
			<>
				<Container className="mt--7" fluid>
					<Row className="justify-content-center">
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">{_id === undefined || _id === null ? "Create" : "Update"} {title}</h3>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<div className="pl-lg-4">
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="code">Code</label>
														<Input
															className="form-control-alternative"
															name="code"
															placeholder="Code"
															type="text"
															value={form.code}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="description">Description</label>
														<Input
															className="form-control-alternative"
															name="description"
															placeholder="Description"
															type="text"
															value={form.description}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_en">Tag EN</label>
														<Input
															className="form-control-alternative"
															name="tag_en"
															placeholder="Tag EN"
															type="text"
															value={form.tag_en}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label className="form-control-label" htmlFor="tag_es">Tag ES</label>
														<Input
															className="form-control-alternative"
															name="tag_es"
															placeholder="Tag ES"
															type="text"
															value={form.tag_es}
															onChange={this.formChange}
														/>
													</FormGroup>
												</Col>
											</Row>
										</div>
										<hr className="my-4" />
										<div className="d-flex justify-content-between">
											<Button color="" onClick={() => this.props.changeView(false)}>Cancelar</Button>
											<Button color="success" onClick={() => this.sendData(form, _id)}>
											{_id === undefined || _id === null ? "Create" : "Update"}
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

			</>
		);
	}
}

export default Formulary;